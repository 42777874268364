import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  Popover,
} from '@material-ui/core';
import { Clear, Settings } from '@material-ui/icons';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';

const CategoryPicker = ({ onChange, categories = [], allCategories = [], small = false }) => {
  const [anchorEl, setAnchorEl] = useState();
  const [searchText, setSearchText] = useState();
  const handleToggle = useCallback(e => {
    setAnchorEl(prev => (prev ? undefined : e.currentTarget));
    e.stopPropagation();
  }, []);

  const handleClose = useCallback(reason => {
    if (reason === 'toggleInput') {
      return;
    }
    anchorEl && anchorEl.focus();
    setAnchorEl(undefined);
  }, []);

  const handleSelect = function (item, checked, e) {
    let newData = [];
    if (checked) newData = categories.filter(_item => _item.title !== item.title);
    else newData = [...categories, item];
    onChange(newData);
  };

  return (
    <Box component={'span'} style={{ position: 'relative', display: 'inline-block' }}>
      <IconButton onClick={handleToggle}>
        <Settings style={small ? { fontSize: '14px' } : {}} />
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Card elevation={6}>
          <CardHeader
            title={
              <Typography variant="subtitle2" align="center">
                Customize table header
              </Typography>
            }
            style={{ paddingBottom: 0 }}
          />
          <CardContent style={{ padding: '10px 0' }}>
            {allCategories.length > 6 && <SearchField value={searchText} onChange={setSearchText} />}
            <List style={{ maxHeight: 200, overflow: 'auto' }}>
              {allCategories.map((item, key) => {
                let checked = !!categories.find(_item => item.title === _item.title);
                return (
                  <Box onClick={e => e.stopPropagation()} key={key}>
                    <ListItem role={undefined} dense button onClick={e => handleSelect(item, checked, e)}>
                      <ListItemIcon>
                        <Checkbox edge="start" size="small" checked={checked} tabIndex={-1} disableRipple />
                      </ListItemIcon>
                      <ListItemText primary={item.title} />
                    </ListItem>
                  </Box>
                );
              })}
            </List>
          </CardContent>
        </Card>
      </Popover>
    </Box>
  );
};

export default CategoryPicker;

const SearchField = ({ value, onChange }) => {
  const handleChange = useCallback(
    e => {
      onChange && onChange(e.target.value);
    },
    [onChange],
  );
  return (
    <TextField
      fullWidth
      placeholder="Search"
      value={value}
      onChange={handleChange}
      InputProps={{
        style: {
          padding: '2px 8px',
        },
        endAdornment: (
          <InputAdornment position="end">
            <Clear fontSize="small" />
          </InputAdornment>
        ),
      }}
    />
  );
};
